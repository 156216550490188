import React, { useState, useContext, useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { team } from "../../../data/pages/homepage";
import { teamData } from "../../../data/pages/team";
import DynamicLink from "../../Link";
import ReactContext from "../../../context/reactContext";
import "./meet.scss";
import classNames from "classnames";

gsap.registerPlugin(ScrollTrigger);

const Meet = ({ photos }) => {
  const { currentTeamMember, setCurrentTeamMember, setOpenDropdown } = useContext(ReactContext);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [hoverItem, setHoverItem] = useState(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  useEffect(() => {}, [hoverItem]);

  const onMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
    cursorRef.current.style.top = `${e.clientY}px`;
    cursorRef.current.style.left = `${e.clientX}px`;
  };

  const onPanelHover = (role, element) => {
    setHoverItem(role);
    cursorRef.current.style.opacity = "1";
  };

  const onPanelOut = (element) => {
    setHoverItem(null);
    cursorRef.current.style.opacity = "0";
  };

  const cursorClasses = classNames("custom-cursor", {
    "custom-cursor--yellow":
      hoverItem === "Director" || hoverItem === "Production Designer",
    "custom-cursor--red": hoverItem === "Producer" || hoverItem === "Editor",
    "custom-cursor--blue":
      hoverItem === "Cinematographer" || hoverItem === "Writer",
  });

  return (
    <div className="rw-meet">
      <div className="rw-meet__inner">
        <div className="rw-meet__index">
          <div
            className="rw-meet__header"
          >
            <h1>Meet</h1>
            <h2>the team</h2>
          </div>
          <div className="rw-meet__spacer"></div>
          {team.map((teamMember) => {
            const { name, photo, id, role } = teamMember;
            const teamHoverPhoto = `${photo}Click`;
            const currentItem = teamData.find((el) => el.id === name);
            return (
              <DynamicLink to="/team">
                <div
                  key={name}
                  className="rw-meet__index__panel"
                  onClick={() => {setCurrentTeamMember(currentItem); setOpenDropdown(currentItem.id)}}
                >
                  <p>{role}</p>
                  <div className="rw-meet__index__panel__top">
                    <GatsbyImage
                      alt={`${name} photo`}
                      image={photos[photo]}
                      onMouseEnter={(e) => onPanelHover(role, e.target)}
                      onMouseLeave={(e) => onPanelOut(e.target)}
                    />
                  </div>
                  <div className="rw-meet__index__panel__bottom">
                    <GatsbyImage
                      alt={`${name} photo hover`}
                      image={photos[teamHoverPhoto]}
                    />
                  </div>
                </div>
              </DynamicLink>
            );
          })}
        </div>
      </div>
      <div ref={cursorRef} className={cursorClasses}>
        About me
      </div>
    </div>
  );
};

export default Meet;
