import * as React from "react";
import { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import gsap, { ScrollTrigger } from "gsap";
import CTA from "../components/Button";
import { graphql } from "gatsby";
import { assetArrayToObject } from "../lib/graphql-helpers";
import Meet from "../components/homepage/meet";
import "../styles/pages/homepage.scss";
import { useCheckScreenSize } from "../lib/useCheckScreenSize";
gsap.registerPlugin(ScrollTrigger);

const Home = ({ data }) => {
  const {
    allFile: { edges: allImages },
  } = data;

  const teamImages = assetArrayToObject({
    useGatsbyImage: true,
    images: allImages,
  });

  const synopsisRef = useRef(null);
  const isMobile = useCheckScreenSize(790);

  useEffect(() => {
    if (synopsisRef.current && (isMobile === false)) {
      let animation = gsap.to(synopsisRef.current, {
        scrollTrigger: {
          trigger: synopsisRef.current,
          start: "top bottom-=100px",
          // toggleActions: "play none none reverse",
          onToggle: (self) => {
            if (!self.isActive) {
              self.kill();
              animation.kill();
            }
          },
        },
        marginTop: -300,
        duration: 0.5,
        ease: "power1.out",
      });
    }
    if (synopsisRef.current && (isMobile === true)) {
      let animation = gsap.to(synopsisRef.current, {
        scrollTrigger: {
          trigger: synopsisRef.current,
          start: "top bottom-=100px",
          // toggleActions: "play none none reverse",
          onToggle: (self) => {
            if (!self.isActive) {
              self.kill();
              animation.kill();
            }
          },
        },
        marginTop: 0,
        duration: 0.5,
        ease: "power1.out",
      });
    }
  }, [isMobile]);

  return (
    <div className="rw-homepage">
      <section className="rw-homepage__hero">
        <StaticImage
          src="../images/homepage/fallingMan.png"
          alt="falling-down man"
          placeholder="blurred"
          className="rw-homepage__hero__man"
        />
        <StaticImage
          src="../images/homepage/heroImage2.png"
          alt="hero background"
          placeholder="blurred"
          layout="fullWidth"
          className="rw-homepage__hero__background"
        />
        <div className="rw-homepage__hero__inner">
          <div className="rw-homepage__hero__content">
            <StaticImage
              src="../images/homepage/heroLogo2.png"
              alt="hero logo"
              placeholder="blurred"
              className="rw-homepage__hero__content__logo"
            />
            <h2>
              After missing her father's funeral, the youngest daughter attempts to
              stop her estranged siblings from selling the family business, a
              failed roller rink
            </h2>
            <CTA
              to="https://www.afi.com/support/?Op=donate&don=500"
              className="rw-homepage__hero__cta"
              isOutbound
              glow={true}
            >
              Donate
            </CTA>
          </div>
        </div>
      </section>
      <div className="rw-homepage__lower-content">
        <StaticImage
          src="../images/homepage/back02.png"
          alt="Background Image"
          placeholder="blurred"
          layout="fullWidth"
          className="rw-homepage__background"
        />
        <section ref={synopsisRef} className="rw-homepage__synopsis">
          <div className="rw-homepage__synopsis__image">
            <StaticImage
              src="../images/homepage/synopsis.png"
              alt="upside-down man"
              placeholder="blurred"
              className="rw-homepage__synopsis__image"
            />
          </div>
          <div className="rw-homepage__synopsis__copy">
            <h1>Synopsis</h1>
            <p>
              Three months after her father's passing, Francis Zapata, the
              youngest of three, works to resurrect the cherished family
              treasure – "RollerWorld," a sanctuary where her fingerprints have
              adorned the walls since the tender age of 5.
            </p>
            <p>
              As old patterns resurface, Francis orchestrates a plan to
              safeguard the rink, securing a second mortgage and launching a
              covert local commercial to counter her older siblings, Lenny and
              Antonia's selling intentions. The three haven't shared a room
              since Francis missed their father’s funeral.
            </p>
          </div>
        </section>
        <section className="rw-homepage__director">
          <div className="rw-homepage__director__inner">
            <div className="rw-homepage__director__content">
              <div className="rw-homepage__director__header">
                <h1>Director's</h1>
                <h2>statement</h2>
              </div>
              <div className="rw-homepage__director__content__text">
                <p>
                  “RollerWorld” is a comedy-drama that explores the magic of
                  nostalgia and the enduring strength of family ties.{" "}
                  <strong>
                    The film is an exploration of letting go while unveiling the
                    well-traveled path immigrants take to success, starting a
                    business.
                  </strong>
                </p>
                <div>
                  <p>
                    <strong>
                      RollerWorld uncovers one of thousands of failing
                      businesses in America. Highlighting the power of shared
                      bonds. A tale of a family's imperfect yet beautiful
                      essence.{" "}
                    </strong>
                  </p>
                </div>
                <p>
                  Through roller skating, laughter, and a few unexpected twists,
                  the film reminds us that sometimes the key to saving what's
                  important lies within the bonds we share. It is a meditation
                  on family. While imperfect, it is fragmented, beautiful,
                  inescapable, and all other manners of contradictory things.
                </p>
              </div>
            </div>
            <div className="rw-homepage__director__image">
              <StaticImage
                src="../images/homepage/directorImage.png"
                alt="director statement"
                placeholder="blurred"
              />
            </div>
          </div>
        </section>
        <Meet photos={teamImages} />
        <section className="rw-homepage__afi">
          <StaticImage
            src="../images/homepage/afiBack.png"
            alt="Background Image"
            placeholder="blurred"
            layout="fullWidth"
            className="rw-homepage__afi__background"
          />
          <div className="rw-homepage__afi__inner">
            <div className="rw-homepage__afi__content">
              <div className="rw-homepage__afi__header">
                <h1>Afi</h1>
                <h2>conservatory</h2>
              </div>
              <p>
                The AFI Conservatory is a non-profit, 501 c3 organization with a
                mandate to champion the moving image as an art form. As such,
                AFI was most recently ranked at No.1 among all the film schools
                in the country by The Hollywood Reporter.
              </p>
              <CTA className="rw-homepage__afi__content__button" to="/afi">
                Learn more
              </CTA>
            </div>
            <div className="rw-homepage__afi__image">
              <StaticImage
                alt="afi number 1"
                width={700}
                src="../images/homepage/afi.png"
              />
            </div>
            <div className="rw-homepage__afi__button-wrapper">
              <CTA className="rw-homepage__afi__button" to="/afi">
                Learn more
              </CTA>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "homepage/team" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          id
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 277
              height: 371
            )
          }
        }
      }
    }
  }
`;

export default React.memo(Home);
