export const team = [
  {
    name: "kim",
    photo: "kim",
    id: 1,
    role: "Director",
  },
  {
    name: "kerisa",
    photo: "kerisa",
    id: 2,
    role: "Producer",
  },
  {
    name: "jessica",
    photo: "jessica",
    id: 3,
    role: "Cinematographer",
  },
  {
    name: "camila",
    photo: "camila",
    id: 4,
    role: "Production Designer",
  },
  {
    name: "xu",
    photo: "xu",
    id: 5,
    role: "Editor",
  },
  {
    name: "raquel",
    photo: "raquel",
    id: 6,
    role: "Writer",
  },
];
